export const shadows = {
  xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
  sm: '0 1px 2px rgba(0, 0, 0, 0.08)',
  md: '0 2px 2px rgba(0, 0, 0, 0.05)',
  lg: '0 10px 20px rgba(0, 0, 0, 0.1)',
  xl: '0 30px 20px rgba(0, 0, 0, 0.15)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
  none: 'none',
  primaryNavOutline: '0 0 0 1px rgba(255, 255, 255, 1)',
};
