import { FetchMiddleware } from './types';
import { createHeaderMiddleware } from './HeaderMiddleware';

/** Configuration data to populate headers for requests originating from this app. */
export interface AppConfig {
  /** Name of the calling application */
  name: string;
  /** Current version of the application */
  version: string;
}

export const createApplicationHeaderMiddleware = (opts: { appConfig: AppConfig }): FetchMiddleware => {
  return createHeaderMiddleware({
    headers: {
      'Application-Name': opts.appConfig.name,
      'Application-Version': opts.appConfig.version,
    },
  });
};
