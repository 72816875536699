import { FetchMiddleware } from './types';

/**
 * Returns a random hex string of a predefined length.
 * @param len The length of the hex string
 * @returns A hex string of length {len}
 */
const randomHex = (len: number) => {
  const characters = '0123456789abcdef';
  let str = '';
  for (let i = 0; i < len; i++) {
    str += characters[Math.floor(Math.random() * 16)];
  }
  return str;
};
/**
 * Add b3 headers to requests so they can be traced to downstream service requests easily.`
 * @returns A FetchMiddleware with B3 headers added
 */
export const createTracingMiddleware = (): FetchMiddleware => {
  return (request) => {
    const traceId = randomHex(32);
    const spanId = randomHex(16);
    const tracingHeaders = {
      'x-b3-traceid': traceId,
      'x-b3-spanid': spanId,
      'x-b3-sampled': Math.random() < 0.001 ? '1' : '0', // sample at 0.1% rate
    };
    return { ...request, headers: { ...request.headers, ...tracingHeaders } };
  };
};
