import querystring from 'qs';

import { HTTPRequestError } from '../errors/error';
import { FetchClient, FetchClientRequest, FetchFn, ContentType } from '../types';

interface DefaultFetchClientOpts {
  fetchFn: FetchFn;
}

function getDefaultOpts(): DefaultFetchClientOpts {
  const emptyFetch: typeof window.fetch = () => {
    return Promise.reject('window.fetch is not defined');
  };

  const globalFetch = typeof window === 'undefined' || !window.fetch ? emptyFetch : window.fetch;

  return { fetchFn: globalFetch };
}

const NO_CONTENT_RESPONSE_STATUS = 204;

/**
 * Takes in a FetchFn and handles the logic creating a request and parsing the response.
 */
export class DefaultFetchClient implements FetchClient {
  private opts: DefaultFetchClientOpts;

  public constructor(opts: Partial<DefaultFetchClientOpts>) {
    this.opts = { ...getDefaultOpts(), ...opts };
  }

  public async get<T>(request: FetchClientRequest): Promise<T> {
    const fetchRequest = this.createRequest(request);
    const response = await this.opts.fetchFn(fetchRequest.url, fetchRequest.init);

    if (!response.ok) {
      throw new HTTPRequestError(await response.text(), response.status, response.statusText, response);
    }

    const contentType = response.headers.get('Content-Type') || '';

    let body: T;
    if (response.status === NO_CONTENT_RESPONSE_STATUS) {
      body = null as any as T;
    } else if (contentType.includes(ContentType.APPLICATION_JSON)) {
      body = await response.json();
    } else if (contentType.includes(ContentType.APPLICATION_OCTET_STREAM)) {
      body = (await response.blob()) as any as T;
    } else {
      body = (await response.text()) as any as T;
    }

    return body;
  }

  private createRequest(request: FetchClientRequest): { url: string; init: RequestInit } {
    const { path, method, requestParams, init = {} } = request;
    const { data, queryParams } = requestParams;
    const url = new URL(path);
    const headers = request.headers ? { ...request.headers } : {};

    let body: string | FormData | undefined;
    if (queryParams && Object.keys(queryParams).length > 0) {
      url.search = querystring.stringify(queryParams, { arrayFormat: 'repeat' });
    }

    if (data instanceof FormData) {
      // Do not set content-type explicitly - fetch api will do it with correct delimiter.
      body = data;
    } else if (method === 'PATCH' || method === 'POST' || method === 'PUT') {
      headers['Content-Type'] = ContentType.APPLICATION_JSON;
      body = JSON.stringify(data);
    }

    return {
      url: url.toString(),
      init: {
        credentials: 'include',
        method,
        headers,
        body,
        ...init,
      },
    };
  }
}
