import { Headers } from '../types';
import { FetchMiddleware } from './types';

export const createHeaderMiddleware = (opts: { headers: Headers }): FetchMiddleware => {
  return (request) => {
    return {
      ...request,
      headers: { ...request.headers, ...opts.headers },
    };
  };
};
