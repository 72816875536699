import { GetConfigValuesRequest, GetConfigValueRequest, GetConfigValuesResponse } from '../types';

interface GetConfigValueRequestWithIndex extends GetConfigValueRequest {
  originalIndex: number;
}

type IndexedRequestsByNamespace = {
  [namespace: string]: GetConfigValueRequestWithIndex[];
};

// Groups requests by namespace for combining fetch requests
// and saves original index so response can maintain the same order
export const requestsByNamespace = (
  configValuesRequest: GetConfigValuesRequest,
  defaultNamespace: string
): {
  requestsMap: IndexedRequestsByNamespace;
  namespaces: string[];
  originalIndexes: number[];
} => {
  const requestsMap = configValuesRequest.requests.reduce<IndexedRequestsByNamespace>((acc, current, index) => {
    const namespace = current.options?.namespace ?? defaultNamespace;
    acc[namespace] = acc[namespace] ?? [];
    acc[namespace].push({ ...current, originalIndex: index });
    return acc;
  }, {});

  const namespaces = Object.keys(requestsMap);
  const originalIndexes = namespaces
    .flatMap((namespace) => requestsMap[namespace])
    .map((request) => request.originalIndex);

  return {
    requestsMap,
    namespaces,
    originalIndexes,
  };
};

// Output results back in original order using `originalIndexes` from requestsByNamespace
export const combineResponsesInOrder = (
  responses: GetConfigValuesResponse[],
  originalIndexes: number[]
): GetConfigValuesResponse => {
  return {
    configValues: responses
      // Combine results (possibly from multiple namespaces)
      .flatMap((response) => response.configValues)
      // Add in information about original request order
      .map((configValue, index) => ({
        ...configValue,
        originalIndex: originalIndexes[index],
      }))
      // Sort results back into their original request order (instead of grouped by namespace)
      .sort((a, b) => a.originalIndex - b.originalIndex)
      // Remove information about original request order
      .map(({ originalIndex, ...rest }) => rest),
  };
};

// Removes extra fields from config value request
export const formatRequest = ({
  name,
  context,
}: GetConfigValueRequestWithIndex): Omit<GetConfigValueRequest, 'options'> => {
  return { name, context };
};
