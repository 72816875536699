export const fontSizes = {
  label: '10px',
  micro: '12px',
  small: '14px',
  paragraph: '16px',
  title: '20px',
  titleLarge: '24px',
  display: '32px',
  displayLarge: '40px',
  mega: '56px',
};
