import retry, { Options } from "async-retry";
import { FetchFn } from "../types";
import { HTTPRequestError } from "../errors/error";
// import { httpStatusRetryable } from '../../api/utils';
// import { RetryOptions } from '../../api/types';

interface RetryingFetchOpts {
  delegate: FetchFn;
  retryOpts?: RetryOptions;
}

/** Check if a HTTP status code is retryable / should be retried */
function httpStatusRetryable(status: number): boolean {
  // Don't retry any 4xx status codes - we only care about 5xx.
  if (status < 500) {
    return false;
  }

  // 501: Not Implemented
  // 505: HTTP version not supported
  // 507: Insufficient Storage (WebDAV)
  // 511: Network Authentication Required
  if (status === 501 || status === 505 || status === 507 || status === 511) {
    return false;
  }

  return true;
}

export interface RetryOptions {
  /**
   * The maximum amount of times to retry the operation.
   * @default 5
   */
  retries?: number;

  /**
   * The exponential factor to use.
   * @default 2
   */
  factor?: number;

  /**
   * The number of milliseconds before starting the first retry.
   * @default 100
   */
  minTimeout?: number;

  /**
   * The maximum number of milliseconds between two retries.
   * @default Infinity
   */
  maxTimeout?: number;

  /**
   * Randomizes the timeouts by multiplying a factor between 1-2.
   * @default true
   */
  randomize?: boolean;

  /**
   * The maximum time (in milliseconds) that the retried operation is allowed to run.
   * @default Infinity
   */
  maxRetryTime?: number;

  /**
   * Optional function that is invoked after a new retry is performed. It's passed the error and a retry number.
   * @param e
   * @param attempt
   */
  onRetry?: (e: Error, attempt: number) => void;
}

/**
 * Wraps a FetchFn and adds retry logic.
 */
export function createRetryingFetch(opts: RetryingFetchOpts): FetchFn {
  const retryOpts: Options = {
    retries: 0, // no retry by default, override in retryOpts
    factor: 2,
    minTimeout: 100,
    randomize: true,
    ...opts.retryOpts,
    // Never allow anyone to set forever in async-retry, even if they bypass our type checks
    forever: false,
  };

  return async (input: string, init?: RequestInit): Promise<Response> => {
    const body = await retry(async (bail) => {
      const response = await opts.delegate(input, init);
      if (response.ok) {
        return response;
      }

      const err = new HTTPRequestError(
        await response.text(),
        response.status,
        response.statusText,
        response
      );

      if (!httpStatusRetryable(response.status)) {
        bail(err);
        throw Error("Unreachable");
      }

      throw err;
    }, retryOpts);

    return body;
  };
}
