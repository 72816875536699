/**
 * These variants come directly from Figma [Typography](https://www.figma.com/file/QDorFoJrLM53TvfUZiOKcB/?node-id=1743%3A5611)
 */
export type TextVariant =
  | 'megaSemiBold'
  | 'megaRegular'
  | 'displayLargeSemiBold'
  | 'displayLargeMedium'
  | 'displayLargeRegular'
  | 'displaySemiBold'
  | 'displayMedium'
  | 'displayRegular'
  | 'titleLargeSemiBold'
  | 'titleLargeMedium'
  | 'titleLargeRegular'
  | 'titleSemiBold'
  | 'titleMedium'
  | 'titleRegular'
  | 'paragraphSemiBold'
  | 'paragraphMedium'
  | 'paragraphRegular'
  | 'smallSemiBold'
  | 'smallMedium'
  | 'smallRegular'
  | 'microSemiBold'
  | 'microMedium'
  | 'microRegular'
  | 'labelSemiBold'
  | 'macroRegular'
  | 'nanoSemiBold';

export type TextColorVariant =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'subtle'
  | 'success'
  | 'inverse'
  | 'subtleInverse';

export type TypeScaleEntry = {
  fontFamily: string;
  fontSize: string;
  lineHeight: number;
  fontWeight: number | string;
  letterSpacing: number | string;
  fontFeatureSettings: string;
};

// This is only used for otter refresh project migration.
// The values are filled in theme provider.
export const refreshTypography: Partial<Record<TextVariant, TypeScaleEntry>> = {};
