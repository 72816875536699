import { Value } from '@css-protos/flagship-api';

import { Primitive } from '../types';

export function resolveValue(value: Value | undefined, fallbackValue: Primitive): Primitive {
  switch (typeof fallbackValue) {
    case 'number': {
      return value?.int64Value ?? value?.doubleValue ?? fallbackValue;
    }
    case 'string': {
      return value?.stringValue ?? fallbackValue;
    }
    case 'boolean': {
      return value?.boolValue ?? fallbackValue;
    }
  }
}
