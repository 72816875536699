import { ContextValue } from '@css-protos/flagship-api';

export function resolveContextValues(context: Record<string, string> | void): Record<string, ContextValue> {
  if (!context) {
    return {};
  }
  return Object.entries(context).reduce<Record<string, ContextValue>>((accumulator, [key, value]) => {
    accumulator[key] = { stringValue: value };
    return accumulator;
  }, {});
}
