/**
 * Constructs the URL to be used as the API_URL for the app.
 * This is mainly used for apps hosted on multiple domains (tryotter.com, cloudkitchens.com).
 * Currently ensures that we use the same host that the app is running under,
 * defaulting to cloudkitchens if we're on localhost.
 *
 * @param subdomain the API subdomain. Example: 'api' or 'api-staging'
 */
export function buildAPIURL(subdomain: string, urlOverride?: string): string {
  if (urlOverride) {
    return urlOverride;
  }
  const { hostname } = window.location;
  if (
    hostname === "localhost" ||
    hostname === "192.168.99.100" ||
    /\.diff-preview\./.test(hostname)
  ) {
    return `https://${subdomain}.cloudkitchens.com`;
  }
  // replace first subdomain with prefix
  return `https://${hostname.replace(/^[^.]*/, subdomain)}`;
}

export interface UrlConfig {
  API_SUBDOMAIN: string; // e.g. 'api' | 'api-staging', passed in through config
  API_URL: string; // derived
  API_OVERRIDE?: string; // e.g. localhost:3000, for testing locally
}

export function getEnvFromHostname(): "production" | "staging" | "local" | "integration" {
  if (typeof window === "undefined") {
    return "local";
  }
  const { hostname } = window.location;
  if (hostname === "localhost" || hostname === "192.168.99.100") {
    return "local";
  }
  if (/-integration\./.test(hostname)) {
    return "integration";
  }
  if (/-staging\./.test(hostname) || /\.diff-preview\./.test(hostname)) {
    return "staging";
  }
  return "production";
}

export function createConfigWithDerivedApiUrl<T extends UrlConfig>(
  partialConfig: Omit<T, "API_URL">
) {
  return {
    ...partialConfig,
    API_URL: buildAPIURL(partialConfig.API_SUBDOMAIN, partialConfig.API_OVERRIDE),
  };
}

export * from "./translateAppUrls";
