const base = {
  white: '#FFFFFF',
  ashLight: '#F9F9F9',
  ashLighter: '#FCFCFC',
  ash: '#F4F4F4',
  ashDark: '#ECECEC',
  greyLighter: '#B9B9B9',
  greyLight: '#A5A5A5',
  grey: '#707070',
  greyDark: '#112232',
  navyLighter: '#E7EAEE',
  navyLight: '#3C5266',
  navy: '#27364D',
  navyDark: '#1F2B3D',
  orangeLighter: '#FAEEEE',
  orangeLight: '#FFE4DE',
  orange: '#FF5530',
  orangeDark: '#EE3B14',
  yellow: '#EE9502',
  yellowLighter: '#FFF5E4',
  yellowLight: '#FFD898',
  yellowMedium: '#FDBF6F',
  yellowDark: '#C27900',
  redLighter: '#FAEEEE',
  redLight: '#FFC0C0',
  red: '#B41313',
  redDark: '#8E0909',
  greenLighter: '#E2F8D3',
  greenLight: '#C0E9A8',
  green: '#1A8914',
  greenDark: '#005729',
  black: '#000000',
  transparentBlack02: 'rgba(0, 0, 0, 0.02)',
  transparentBlack05: 'rgba(0, 0, 0, 0.05)',
  transparentBlack10: 'rgba(0, 0, 0, 0.1)',
  transparentBlack15: 'rgba(0, 0, 0, 0.15)',
  transparentBlack20: 'rgba(0, 0, 0, 0.2)',
};

const aliases = {
  primary: base.navyLight,
  primaryDark: base.navy,
  secondary: base.white,
  secondaryDark: base.white,
  tertiary: base.ash,
  tertiaryDark: base.ashDark,
};

export const dataVisualizationPalette = [
  base.navyLight,
  '#FF886E',
  '#66C7B4',
  '#FE9713',
  '#F36776',
  '#70A2FE',
  '#3A9A55',
  '#F98139',
  '#9462FE',
  '#FFD50B',
  '#FF9EC8',
  '#FDBF6F',
];

export const colors = {
  ...aliases,
  ...base,
};
