import { FetchFn, AuthTokenProvider } from '../types';

/**
 * Where the auth token is persisted in local storage.
 */
const AUTH_TOKEN_KEY = 'css.authtoken';

/**
 * A default AuthTokenProvider which uses localstorage
 */
export class DefaultTokenProvider implements AuthTokenProvider {
  public getToken(): string | undefined {
    return getAuthToken();
  }

  public setToken(token: string): void {
    setAuthToken(token);
  }

  public clearToken(): void {
    logout();
  }
}

/**
 * Log the user out by removing his Auth Token.
 */
export function logout(): void {
  try {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  } catch (e) {
    console.warn('Error removing cached auth token', e);
  }
}

/**
 * Checks if the user is logged in by checking if the auth token exists.
 * NOTE: this does not check if the token is still valid.
 */
export function isLoggedIn(): boolean {
  return !!localStorage.getItem(AUTH_TOKEN_KEY);
}

/**
 * Get AuthToken if it exists
 */
export function getAuthToken(): string | undefined {
  try {
    return localStorage.getItem(AUTH_TOKEN_KEY) || undefined;
  } catch (e) {
    console.warn('Error fetching persisted auth token', e);
  }
}

/**
 * Store AuthToken locally
 */
export function setAuthToken(token: string): void {
  try {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  } catch (e) {
    console.warn('Error persisting auth token', e);
  }
}

export interface AuthFetchOpts {
  delegate: FetchFn;
  tokenProvider?: AuthTokenProvider;
}

const GET_USER_ENDPOINT = '/me';
const SIGN_OUT_ENDPOINT = '/sign_out';

/**
 * Wraps a FetchFn and adds CSS specific Auth.
 */
export class AuthFetch {
  private tokenProvider: AuthTokenProvider;

  public constructor(private opts: AuthFetchOpts) {
    this.tokenProvider = opts.tokenProvider ? opts.tokenProvider : new DefaultTokenProvider();
  }

  public get fetch(): FetchFn {
    return this.authFetch;
  }

  private authFetch: FetchFn = async (input: string, init?: RequestInit) => {
    const token = this.tokenProvider.getToken();

    const headers = new Headers(init?.headers);
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    const response = await this.opts.delegate(input, { ...init, headers });
    this.maybeRemoveToken(input, response);
    this.maybeUpdateToken(response);

    return response;
  };

  private maybeRemoveToken(input: string, response: Response): void {
    // Whenever a call is made to /sign_out, then invalidate the current api token.
    // If we got a 401 on the /me endpoint (getUser), then invalidate the current api token.
    // We don't want to log the user out on ALL 401s b/c some backends will return 401 as a catch all error
    // as to not open up our backends for exploitation.
    if (input.endsWith(SIGN_OUT_ENDPOINT) || (response.status === 401 && input.endsWith(GET_USER_ENDPOINT))) {
      this.tokenProvider.clearToken();
    }
  }

  private maybeUpdateToken(response: Response): void {
    // If we have an auth header, update our token.
    const authHeader = response.headers.get('Authorization');
    if (authHeader && response.status !== 401) {
      // Auth header should have the form `Bearer {token}`
      const components = authHeader.split(' ');
      const token = components[1];
      this.tokenProvider.setToken(token);
    }
  }
}
