export const lineHeights = {
  small: '0.5',
  none: '1',
  tight: '1.25',
  snug: '1.375',
  normal: '1.5',
  relaxed: '1.625',
  loose: '2',
  body: '1.625',
  heading: '1.25',
};
