import React, { useContext } from 'react';
import styled, { css, DefaultTheme, keyframes, ThemeContext } from 'styled-components';

import { SizeVariant } from '../../types';

export interface SpinnerProps {
  /**
   * How large should the spinner be?
   * @default medium
   */
  size?: SizeVariant;

  /**
   * What color to use.
   * @default primary
   */
  color?: keyof DefaultTheme['colors'];

  /**
   * Since the Spinner can not use a transparent background, the inner cirlce needs to have a declared background if you are showing this on something other than white.
   * @default white
   */
  backgroundColor?: keyof DefaultTheme['colors'];

  /**
   * Test id is attached to the svg.
   */
  dataTest?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({
  color,
  backgroundColor,
  dataTest = 'loading-spinner',
  size = 'medium',
}) => {
  const theme = useContext(ThemeContext);
  const themeColor = color && (theme.colors as Record<string, string>)?.[color];
  const themeBackgroundColor = backgroundColor && (theme.colors as Record<string, string>)?.[backgroundColor];

  return (
    <OuterCircle
      data-testid={dataTest}
      size={size}
      color={(themeColor || theme.colors.grey) as keyof DefaultTheme['colors']}
      backgroundColor={(themeBackgroundColor || theme.colors.white) as keyof DefaultTheme['colors']}
    >
      <div />
    </OuterCircle>
  );
};

const rotatingKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const OuterCircle = styled.div<
  SpinnerProps & { color: keyof DefaultTheme['colors']; backgroundColor: keyof DefaultTheme['colors'] }
>`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: conic-gradient(rgba(255, 85, 48, 0) 17%, ${(props) => props.color});
  animation: ${rotatingKeyframes} 0.6s linear infinite;

  > div {
    height: 52px;
    width: 52px;
    border-radius: 50%;
    background: ${(props) => props.backgroundColor};
    position: absolute;
  }

  ${(props) =>
    props.size === 'extra-small' &&
    css`
      height: 16px;
      width: 16px;
      > div {
        margin: 2px;
        height: 12px;
        width: 12px;
      }
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      height: 32px;
      width: 32px;
      > div {
        margin: 4px;
        height: 24px;
        width: 24px;
      }
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      height: 64px;
      width: 64px;
      > div {
        margin: 6px;
        height: 52px;
        width: 52px;
      }
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      height: 128px;
      width: 128px;
      > div {
        margin: 7px;
        height: 114px;
        width: 114px;
      }
    `}
`;
