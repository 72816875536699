/**
 * Relative to size 1rem = 16px (ie., pixel = 4 * $n)
 * $1 = 4px
 * $6 = 24px
 */

export const space = {
  $px: '1px',
  $0: '0',
  $1: '0.25rem',
  $2: '0.5rem',
  $3: '0.75rem',
  $4: '1rem',
  $5: '1.25rem',
  $6: '1.5rem',
  $7: '1.75rem',
  $8: '2rem',
  $9: '2.25rem',
  $10: '2.5rem',
  $11: '2.75rem',
  $12: '3rem',
  $14: '3.5rem',
  $15: '3.75rem',
  $16: '4rem',
  $18: '4.5rem',
  $20: '5rem',
  $24: '6rem',
  $28: '7rem',
  $32: '8rem',
  $36: '9rem',
  $40: '10rem',
  $48: '12rem',
  $56: '14rem',
  $64: '16rem',
  $84: '21rem',
  $90: '22.5rem',
  $100: '25rem',
  $110: '27.5rem',
  $200: '50rem',
  $400: '100rem',
};
