import { BreakpointMap, BreakpointVariant } from '../../components/types';

// TODO: Use ems?
// https://zellwk.com/blog/media-query-units/
export const BREAKPOINT_UNIT = 'px';

/**
 * Rather than define breakpoints based on famous device sizes (which are often out of date), we
 * want out breakpoints to be consistently spaced and reasonably future proof (without allowing)
 * for an infinite number of breakpoints. Having a breakpoint available every couple hundred pixels
 * should reasonably suite our needs for some time. Because our theme’s space values are based on a
 * 16px (1rem) base unit, premising our breakpoint on a 160px (10 space) base unit seems reasonable.
 *
 * Like our space values, we don't expose each and every possible value; but the naming convention
 * allows us to fill in missing values once more flexibility is needed. With a 160px base unit,
 * the complete list of our breakpoints will be:
 * $1: 160
 * $2: 320
 * $3: 480
 * $4: 640
 * $5: 800
 * $6: 960
 * $7: 1120
 * $8: 1280
 * $9: 1440
 * $10: 1600
 * $11: 1760
 * $12: 1920
 *
 * HOWEVER, this is probably overkill, so in the interest of paving the cow paths, we'll start by
 * only exposing the even numbered breakpoints and add odd increments as the need arises.
 */
export const BreakpointMinWidths: BreakpointMap<number> = {
  $2: 320,
  $3: 480,
  $4: 640,
  $5: 800,
  $6: 960,
  $7: 1120,
  $8: 1280,
  $9: 1440,
  $10: 1600,
  $11: 1760,
  $12: 1920,
};

// 0.02px is subtracted to ensure there is no overlap in ranges
// and that no values fall out of range. See:
// https://getbootstrap.com/docs/5.0/layout/breakpoints/#max-width
// https://www.w3.org/TR/mediaqueries-4/#mq-min-max
const EPSILON = 0.02;
export const BreakpointMaxWidths: BreakpointMap<number> = {
  $2: BreakpointMinWidths.$3 - EPSILON,
  $3: BreakpointMinWidths.$4 - EPSILON,
  $4: BreakpointMinWidths.$5 - EPSILON,
  $5: BreakpointMinWidths.$6 - EPSILON,
  $6: BreakpointMinWidths.$7 - EPSILON,
  $7: BreakpointMinWidths.$8 - EPSILON,
  $8: BreakpointMinWidths.$9 - EPSILON,
  $9: BreakpointMinWidths.$10 - EPSILON,
  $10: BreakpointMinWidths.$11 - EPSILON,
  $11: BreakpointMinWidths.$12 - EPSILON,
  $12: Infinity,
};

export const breakpoints = Object.keys(BreakpointMinWidths).reduce(
  (breakpointMap, breakpoint) => ({
    ...breakpointMap,
    [breakpoint]: `${BreakpointMinWidths[breakpoint as BreakpointVariant]}${BREAKPOINT_UNIT}`,
  }),
  {} as BreakpointMap<string>
);
