import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";

import { FeatureProvider } from "@css/feature-flags";
import { theme } from "@css/op-components";

import App from "./App";
import { initMixpanel, initPageTracker, trackWebVitals } from "./common/lytics";
import CONFIG from "./config/config";
import { queryClient } from "./queryClient";
import { BrowserRouter } from "react-router-dom";
// import { RosettaProvider } from './rosetta';

const history = createBrowserHistory();
initMixpanel();
initPageTracker(history);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <FeatureProvider
          apiUrl={CONFIG.API_URL}
          appConfig={{ name: CONFIG.APP_NAME, version: "" }}
          namespace={CONFIG.FLAGSHIP_NAMESPACE}
        >
          <App />
        </FeatureProvider>
      </ThemeProvider>
    </BrowserRouter>
  </QueryClientProvider>
  // </React.StrictMode>
);

import("web-vitals").then(({ onCLS, onFID, onFCP, onLCP, onTTFB }) => {
  onCLS(trackWebVitals);
  onFID(trackWebVitals);
  onFCP(trackWebVitals);
  onLCP(trackWebVitals);
  onTTFB(trackWebVitals);
});
