import {
  colors,
  dataVisualizationPalette,
  fontFamily,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  space,
  breakpoints,
  textDecorations,
  refreshTypography,
} from './constants';

export const theme = {
  breakpoints,
  colors,
  dataVisualizationPalette,
  fontFamily,
  space,
  fontWeights,
  fontSizes,
  lineHeights,
  letterSpacings,
  radii,
  shadows,
  textDecorations,
  refreshTypography,
};

export type OpTheme = typeof theme;

export type OpThemeColor = keyof OpTheme['colors'];
