/**
 * Custom Error that stores information about an HTTP request/response for later propogation
 */
export class HTTPRequestError extends Error {
  public responseText: string;
  public status: number;
  public statusText: string;
  public response: Response;

  public constructor(responseText: string, status: number, statusText: string, response: Response, message?: string) {
    super(message ? message : responseText);
    this.name = 'HTTPRequestError';
    this.responseText = responseText;
    this.status = status;
    this.statusText = statusText;
    this.response = response;

    // This function is only available on V8 based browsers
    if ('captureStackTrace' in Error) {
      (Error as any).captureStackTrace(this, HTTPRequestError);
    }
  }
}
