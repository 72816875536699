import { FetchClient, FetchClientRequest } from '../types';

interface DebouncingFetchClientOpts {
  delegate: FetchClient;
}

/**
 * Tracks inflight requests to avoid multiple requests for the same path/method.
 */
export class DebouncingFetchClient implements FetchClient {
  // NOTE: this could be a memory leak but we delete Promises from the map
  // as they complete, and we do not expect the number of inflight promises
  // to be high
  private inflightRequests = new Map<string, Promise<any>>();

  public constructor(private opts: DebouncingFetchClientOpts) {}

  public async get<T>(request: FetchClientRequest): Promise<T> {
    // if not debouncing, just delegate
    if (!request.debounce) {
      return this.opts.delegate.get<T>(request);
    }

    // check if it's inflight
    const key = toKey(request);
    if (this.inflightRequests.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.inflightRequests.get(key)!;
    }

    // make the request and set it to inflight
    const responsePromise = this.opts.delegate.get<T>(request);
    this.inflightRequests.set(key, responsePromise);

    try {
      const response = await responsePromise;
      return response;
    } finally {
      // always remove it from the inflight Map after it resolves/rejects
      this.inflightRequests.delete(key);
    }
  }
}

/**
 * Creates an index key from FetchClientRequest based on the path and method.
 */
export function toKey({ path, method }: FetchClientRequest): string {
  return `${path}-${method}`;
}
