import { useQuery } from "react-query";
import { JSBIv1, v1 as JSBClient } from "../util/jsBridge/client";

declare global {
  interface Window {
    __jsbi__: JSBIv1;
  }
}

export const jsbc = new JSBClient();
export type JSBMethods = "getUserId" | "getUserToken" | "getFacilityId" | "getVasStoreId";

interface Options {
  enabled: boolean;
  [key: string]: any;
}
export function useJSB(methodName: JSBMethods, options?: Options) {
  const res = useQuery(
    [methodName],
    async function () {
      return await jsbc[methodName]();
    },
    {
      retry: 3,
      onError(err) {
        console.error(`Error JSB-${methodName}`, err);
        return Promise.resolve();
      },
      ...(options ?? {}),
    },
  );
  return {
    ...res,
    isLoading: options?.enabled && res.isLoading,
  };
}
