import { FetchClient } from '@css/web-core';

import { FeatureFlag } from './FeatureFlag';
import { GetConfigValueRequest, GetConfigValuesRequest, GetConfigValuesResponse, Primitive } from './types';
import { resolveContextValues, resolveValue } from './utils';
import { formatRequest, requestsByNamespace, combineResponsesInOrder } from './utils/requestsByNamespace';

export interface FeatureService {
  readonly defaultNamespace: string;
  getConfigValue<R extends Primitive>(feature: FeatureFlag<unknown, R>): Promise<R>;
  getConfigValues<R1>(features: [FeatureFlag<unknown, R1>]): Promise<[R1]>;
  getConfigValues<R1, R2>(features: [FeatureFlag<unknown, R1>, FeatureFlag<unknown, R2>]): Promise<[R1, R2]>;
  getConfigValues<R1, R2, R3>(
    features: [FeatureFlag<unknown, R1>, FeatureFlag<unknown, R2>, FeatureFlag<unknown, R3>]
  ): Promise<[R1, R2, R3]>;
  getConfigValues<R1, R2, R3, R4>(
    features: [FeatureFlag<unknown, R1>, FeatureFlag<unknown, R2>, FeatureFlag<unknown, R3>, FeatureFlag<unknown, R4>]
  ): Promise<[R1, R2, R3, R4]>;
  getConfigValues<R1, R2, R3, R4, R5>(
    features: [
      FeatureFlag<unknown, R1>,
      FeatureFlag<unknown, R2>,
      FeatureFlag<unknown, R3>,
      FeatureFlag<unknown, R4>,
      FeatureFlag<unknown, R5>
    ]
  ): Promise<[R1, R2, R3, R4, R5]>;
  getConfigValues(features: Array<FeatureFlag<unknown, any>>): Promise<any[]>;
}

export class FeatureServiceImpl implements FeatureService {
  private readonly fetch: (request: GetConfigValuesRequest) => Promise<GetConfigValuesResponse>;
  readonly defaultNamespace: string;

  public constructor(defaultNamespace: string, client: FetchClient) {
    this.defaultNamespace = defaultNamespace;

    this.fetch = (configValuesRequest) => {
      const { requestsMap, namespaces, originalIndexes } = requestsByNamespace(configValuesRequest, defaultNamespace);
      return Promise.all(
        namespaces.map((namespace) => {
          return client.get<GetConfigValuesResponse>({
            path: `/flagship/namespaces/${namespace}`,
            method: 'POST',
            requestParams: {
              data: { requests: requestsMap[namespace].map(formatRequest) },
            },
            headers: {},
          });
        })
      ).then((responses) => combineResponsesInOrder(responses, originalIndexes));
    };
  }

  public getConfigValue<R extends Primitive>(feature: FeatureFlag<unknown, R>): Promise<R> {
    const response = this.getConfigValues([feature]);
    return response.then(([value]) => value);
  }

  public getConfigValues(features: Array<FeatureFlag<any, any>>): Promise<any> {
    const requests: GetConfigValueRequest[] = features.map((feature) => ({
      ...feature,
      context: resolveContextValues(feature.context),
    }));

    return this.fetch({ requests }).then(({ configValues }) => {
      return features.map((feature, index) => {
        const configValue = configValues[index];
        return resolveValue(configValue, feature.fallbackValue);
      });
    }) as Promise<any[]>;
  }
}

export interface LocalFeatureServiceOpts {
  /**
   * All features that return a string fallback value will return this value instead
   *
   * The following options that precedence over this one:
   * - namesReturnValues
   */
  allStringReturnValue?: string | ((feature: FeatureFlag<any, string>) => string) | (() => string);

  /**
   * All features that return a number fallback value will return this value instead
   *
   * The following options that precedence over this one:
   * - namesReturnValues
   */
  allNumberReturnValue?: number | ((feature: FeatureFlag<any, number>) => number);

  /**
   * All features that return a boolean fallback value will return this value instead
   *
   * The following options that precedence over this one:
   * - namesReturnValues
   */
  allBoolReturnValue?: boolean | ((feature: FeatureFlag<any, boolean>) => boolean);

  /**
   * All features will return this value instead
   *
   * The following options that precedence over this one:
   * - allStringReturnValue
   * - allNumberReturnValue
   * - allBoolReturnValue
   * - namesReturnValues
   */
  allReturnValue?: any | ((feature: FeatureFlag<any, any>) => any);

  /**
   * Any feature names present here will return the mapped value.
   *
   * Overrides any other LocalFeatureServiceOpts preferences.
   */
  namesReturnValues?: {
    [name: string]: Primitive | ((feature: FeatureFlag<any, Primitive>) => Primitive);
  };
}

/**
 *
 * string | number | boolean
 *
 * A local only FeatureService
 *
 * By default will return the fallback value of all features, unless specified otherwise with LocalFeatureServiceOpts
 */
export class LocalFeatureServiceImpl implements FeatureService {
  private opts: LocalFeatureServiceOpts;
  readonly defaultNamespace: string;

  public constructor(opts: LocalFeatureServiceOpts) {
    this.opts = opts;
    this.defaultNamespace = '';
  }

  public getConfigValue<R extends Primitive>(feature: FeatureFlag<unknown, R>): Promise<R> {
    const response = this.getConfigValues([feature]);
    return response.then(([value]) => value);
  }

  public getConfigValues(features: Array<FeatureFlag<any, any>>): Promise<any> {
    return Promise.resolve(
      features.map((feature) => {
        if (this.opts.namesReturnValues?.[feature.name]) {
          return this.primitiveOrFunc(feature, this.opts.namesReturnValues[feature.name]);
        }

        if (this.opts.allBoolReturnValue && typeof feature.fallbackValue === 'boolean') {
          return this.primitiveOrFunc(feature, this.opts.allBoolReturnValue);
        }

        if (this.opts.allNumberReturnValue && typeof feature.fallbackValue === 'number') {
          return this.primitiveOrFunc(feature, this.opts.allNumberReturnValue);
        }

        if (this.opts.allStringReturnValue && typeof feature.fallbackValue === 'string') {
          return this.primitiveOrFunc(feature, this.opts.allStringReturnValue);
        }

        if (this.opts.allReturnValue) {
          return this.primitiveOrFunc(feature, this.opts.allReturnValue);
        }

        return feature.fallbackValue;
      })
    );
  }

  private primitiveOrFunc<R extends Primitive>(
    feature: FeatureFlag<any, R>,
    val: R | ((feature: FeatureFlag<any, R>) => R) | (() => R)
  ): R {
    if (typeof val === 'function') {
      return val(feature);
    }

    return val;
  }
}
