import React, { useEffect, useMemo, useRef, useState, lazy, Suspense } from "react";
import { ErrorBoundary, ErrorBoundaryProps } from "react-error-boundary";
import { Routes, Route, Navigate } from "react-router-dom";

import "./common/util/sentry";
import { ErrorScreen } from "./common/components/ErrorScreen";
import { routingPaths } from "./common/constants";
import { identifyUser } from "./common/lytics";
import { fetchClient } from "./common/util/fetchClient";
import { CurrentUserContext, User } from "./hooks/useCurrentUser";
// import { ActivatePage } from './pages/activate';
// import StartTrialPage from './pages/activate/StartTrialPage';
// import CouponSettingPage from './pages/couponsettings/CouponSettingsPage';
// import { InAppPurchasePage } from './pages/iap';
// import LandingPage from './pages/landing/LandingPage';
// import MarketingPage from './pages/marketing/MarketingPage';
// import { OrderListPage } from './pages/orderlist';
// import PaymentPage from './pages/payment/PaymentPage';
// import { ReportPage } from './pages/report';
// import { SelectStorePage } from './pages/selectstore';
// import XcdIndex from "./pages/xcd";
import useSearchParams from "./hooks/useSearchParams";
import { LoadingScreen } from "./common/components/LoadingScreen";
import { isInChinaOtterApp } from "./util/userAgent";
import { useJSB } from "./hooks/useJSB";
import { useCurrentStore } from "./hooks/useCurrentStore";
import useIsGoXcd from "./pages/xcd/api/useIsGoXcd";

const XcdIndex = lazy(() => import("./pages/xcd"));
const FreeTrial = lazy(() => import("./pages/xcd/freeTrial/FreeTrial"));
const CashbackIndex = lazy(() => import("./pages/cashback"));
const OrderBoosterTransition = lazy(() => import("./pages/xcd/orderBooster"));

const MAKE_UP_SOUCE = [/*"splash",*/ "popup"];

const POPUP_CONTENT = "FREE_TRIAL";

const AUTH_TOKEN_STORAGE_KEY = "css.authtoken";

const App: React.FC = () => {
  const authToken = useMemo(() => localStorage.getItem(AUTH_TOKEN_STORAGE_KEY), []);
  const [userId, setUserId] = useState<string>();
  const isChinaOtterApp = useMemo(() => isInChinaOtterApp(), []);

  const { data: vasUserId } = useJSB("getUserId", { enabled: isChinaOtterApp });
  const { data: vasStoreId } = useJSB("getVasStoreId", { enabled: isChinaOtterApp });

  const { utm_source, utm_content, storeId } = useSearchParams();
  const setStoreId = useCurrentStore((state) => state.setStoreId);

  useEffect(() => {
    if (authToken && !isChinaOtterApp) {
      fetchClient
        .get<{ user: { userId: string; organizationId: string } }>({
          path: "/users/me",
          method: "GET",
          requestParams: {},
        })
        .then(({ user }) => {
          identifyUser(user.userId);
          setUserId(user.userId);
        });
    }
  }, [authToken, isChinaOtterApp]);

  const sourceFromSplashOrPopup: boolean = useMemo(
    () => MAKE_UP_SOUCE.includes(utm_source) && utm_content === POPUP_CONTENT,
    [],
  );

  const refreshUrl = useRef(window.location.href.replace(/utm_source=\w*(&{0,1}|$)/, ""));

  if (!authToken) {
    return <ErrorScreen title="身份认证失败" msg="请在重新登录后重试" />;
  }

  const ErrorHandler: ErrorBoundaryProps["onError"] = (error, componentStack) => {
    console.error(error.message, componentStack.componentStack);
  };

  useEffect(() => (isChinaOtterApp ? setStoreId(vasStoreId || storeId) : setStoreId(storeId)), [vasStoreId]);

  console.warn("[AliCloud Version] XCD InApp Web (React) v1.0.1");

  const { isGoXcd } = useIsGoXcd();

  return (
    <CurrentUserContext.Provider value={{ userId: isInChinaOtterApp() ? vasUserId : userId }}>
      <ErrorBoundary onError={ErrorHandler} FallbackComponent={() => <></>}>
        <Suspense fallback={<LoadingScreen />}>
          {sourceFromSplashOrPopup ? (
            <FreeTrial refreshUrl={refreshUrl.current} />
          ) : (
            <Routes>
              <Route
                path={routingPaths.xcd}
                element={
                  isGoXcd ? (
                    <XcdIndex />
                  ) : isGoXcd === false ? (
                    <Navigate to={routingPaths.transition} replace={true} />
                  ) : null
                }
              />
              <Route path={routingPaths.cashback} element={<CashbackIndex />} />
              <Route path={routingPaths.transition} element={<OrderBoosterTransition />} />
            </Routes>
          )}
        </Suspense>
      </ErrorBoundary>
    </CurrentUserContext.Provider>
  );
};

export default App;
