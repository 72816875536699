import React from "react";
import styled from "styled-components";

import { Spinner } from "@css/op-components";

interface LoadingProps {
  height?: string;
  color?: string;
}

const LoadingDiv = styled.div<LoadingProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  ${(props) => (props.height ? `height: ${props.height}px;` : "")}
  ${(props) => (props.color ? `color: ${props.color};` : "")}
`;

const Message = styled.div`
  padding: 10px 0;
  font-size: 18px;
  margin-top: 20px;
`;

export const LoadingScreen: React.FC<LoadingProps & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <LoadingDiv {...props}>
      <Spinner />
      <Message>加载中...</Message>
    </LoadingDiv>
  );
};
