import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type Store = {
  storeId?: string;
  setStoreId: (storeId?: string) => void;
};

export const useCurrentStore = create(
  immer<Store>((set) => ({
    storeId: undefined,
    setStoreId: (storeId?: string) => set({ storeId }),
  })),
);
