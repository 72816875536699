type Version = '1';

export type JSBIBase<T extends {} = {}> = T & {
  version: '1';
  notifyUpdate: () => void;
};

export class JSBBase {
  public bridgeVersion: Version;

  constructor({ bridgeVersion }: { bridgeVersion: Version }) {
    this.bridgeVersion = bridgeVersion;
    this.ensureInjectScriptVersion();
  }

  private ensureInjectScriptVersion(): void {
    const injectScriptVersion = window.__jsbi__?.version;
    const insideWebview = typeof injectScriptVersion !== 'undefined';
    if (insideWebview && !this.compareVersion(injectScriptVersion)) {
      this.notifyUpdate();
    }
  }

  private notifyUpdate() {
    window.__jsbi__?.notifyUpdate();
  }

  private compareVersion(injectScriptVersion?: Version) {
    // TODO: TBD
    return this.bridgeVersion === injectScriptVersion;
  }
}
