import { History } from "history";
import mixpanel, { Dict } from "mixpanel-browser";
import { ReportCallback } from "web-vitals";

import CONFIG, { isProductionEnvironment } from "../config/config";

export const initMixpanel = (): void => {
  mixpanel.init(isProductionEnvironment ? CONFIG.MIXPANEL_PROJECT_TOKEN : CONFIG.MIXPANEL_DEBUG_PROJECT_TOKEN, {
    debug: window?.location.hostname.startsWith("192.168.99.100") ?? false,
  });
  mixpanel.register({ "app name": CONFIG.APP_NAME });
};

export const identifyUser = (userId: string): void => {
  mixpanel.identify(userId);
  mixpanel.people.set({ userId });
};

export const initPageTracker = (history: History): void => {
  trackPageView();

  history.listen(({ location }) => {
    trackPageView(location);
  });
};

const trackApiEvent = (eventName: string, url: URL, init?: RequestInit, eventProps?: Dict): void => {
  if (!(url.hostname.endsWith(".shuitazhanggui.com") || url.hostname.endsWith(".cloudkitchens.com"))) {
    return;
  }

  const props = {
    method: init?.method ?? "GET",
    body: init?.body,
    searchParams: Object.fromEntries(url.searchParams),
    pathname: url.pathname,
    ...eventProps,
  };

  mixpanel.track(eventName, props);
};

const isValidUrl = (input: string) => {
  try {
    return !!new URL(input);
  } catch {
    return false;
  }
};

export const initFetchTracker = (): void => {
  const _fetch = window.fetch;
  window.fetch = async (...args) => {
    const [input, init] = args;

    if (!isValidUrl) {
      return _fetch.apply(window, args);
    }

    const url = new URL(input as string);
    const startTime = performance.now();
    return new Promise((resolve, reject) => {
      _fetch
        .apply(window, args)
        .then((res) => {
          trackApiEvent("Api Call", url, init, { duration: performance.now() - startTime });
          if (!res.ok) {
            trackApiEvent("Api Error", url, init, { status: res.status });
          }

          resolve(res);
        })
        .catch((error) => {
          trackApiEvent("Api Exception", url, init, { error });
          reject(error);
        });
    });
  };
};

export const trackPageView = (
  location: { pathname: string; search: string } = window.location,
  props: Record<string, any> = {},
): void => {
  const xcdProps = location.pathname.startsWith("/xcd/") ? { isXcd: true } : {};
  mixpanel.track("Page View", {
    pathname: location.pathname,
    ...Object.fromEntries(new URLSearchParams(location.search)),
    ...xcdProps,
    ...props,
  });
};

export const trackAction = (action: string, props?: Dict): void => {
  mixpanel.track("User Action", { ...props, action });
};

export const trackEvent = (eventName: string, props?: Dict): void => {
  mixpanel.track(eventName, { ...props });
};

export const trackXcdAction = (action: string, props?: Dict): void => {
  mixpanel.track("User Action", { ...props, action, isXcd: true });
};

export const trackWebVitals: ReportCallback = ({ id, name, value }) => {
  mixpanel.track("Web Vitals", {
    label: id,
    action: name,
    value: Math.round(name === "CLS" ? value * 1000 : value),
    pathname: window.location.pathname,
  });
};

export const trackXcdEvent = (eventName: string, props?: Dict): void => {
  mixpanel.track(eventName, { ...props, isXcd: true });
};
