import React, { useEffect } from "react";
import styled from "styled-components";

interface ErrorProps {
  msg?: string;
  title?: string;
}

const ErrorDiv = styled.div`
  padding: 12px;
  padding-top: 100px;
`;

const ErrorImg = styled.img`
  display: block;
  width: 190px;
  height: 190px;
  margin: 0 auto;
`;

const ErrorTitle = styled.div`
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-top: 32px;
  margin-bottom: 28px;
  color: #112232;
`;

const ErrorMsg = styled.div`
  display: block;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #707070;
  opacity: 0.7;
`;

export const ErrorScreen: React.FC<ErrorProps> = ({ msg, title = "异常" }) => {
  useEffect(() => {
    console.error("error msg", msg);
  }, []);
  return (
    <ErrorDiv data-testid="error_screen">
      <ErrorImg src="/static/common/error.png" alt="出错信息" />
      <ErrorTitle>{title}</ErrorTitle>
      {msg && <ErrorMsg>{msg}</ErrorMsg>}
    </ErrorDiv>
  );
};
