import React, { useState, useContext } from "react";

export interface User {
  userId?: string;
  // organizationId?: string;
}
export const CurrentUserContext = React.createContext<User>({ userId: undefined });

const useCurrentUser = () => {
  const user = useContext(CurrentUserContext);
  return user;
};

export default useCurrentUser;
