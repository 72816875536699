import { AppConfig, createDefaultFetchClient, FetchClient } from "@css/web-core";
import React, { useMemo, ReactNode } from "react";

import { FeatureContext } from "./FeatureContext";
import { FeatureService, FeatureServiceImpl } from "./FeatureService";

interface FeatureProviderProps {
  apiUrl: string;
  appConfig: AppConfig;
  namespace: string;
  fetchClient?: FetchClient;
  featureService?: FeatureService;
  children?: ReactNode | undefined;
}

export const FeatureProvider: React.FC<FeatureProviderProps> = ({
  apiUrl,
  appConfig,
  namespace,
  fetchClient,
  children,
  featureService,
}) => {
  const context = useMemo(() => {
    return {
      featureService: featureService
        ? featureService
        : new FeatureServiceImpl(
            namespace,
            fetchClient ? fetchClient : createDefaultFetchClient({ appConfig, baseUrl: apiUrl })
          ),
    };
  }, [apiUrl, appConfig, namespace]);

  return <FeatureContext.Provider value={context}>{children}</FeatureContext.Provider>;
};
