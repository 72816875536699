/**
 * A slightly more restrictive API of window.fetch
 */
export type FetchFn = (input: string, init?: RequestInit) => Promise<Response>;

/**
 * Implementers of this interface can provide authorization tokens to an AuthFetch client
 */
export interface AuthTokenProvider {
  /**
   * Retrieve the current token, or undefined if none exist
   */
  getToken: () => string | undefined;

  /**
   * Set the current token
   */
  setToken: (token: string) => void;

  /**
   * Clear the token / logout
   */
  clearToken: () => void;
}

type Primitive = string | number | boolean;

export interface Headers {
  [key: string]: string;
}

export interface RequestParams {
  pathParams?: Primitive[];

  queryParams?: { [key: string]: Primitive | Primitive[] };

  data?: unknown;
}

export enum ContentType {
  APPLICATION_JSON = 'application/json',
  APPLICATION_OCTET_STREAM = 'application/octet-stream',
}

export interface FetchClientRequest {
  path: string;

  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

  requestParams: RequestParams;

  headers?: Headers;

  debounce?: boolean;

  init?: RequestInit;
}

export interface FetchClient {
  get<T>(request: FetchClientRequest): Promise<T>;
}
