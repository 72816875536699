const CN_OTTER_APP_USER_AGENT = 'OtterApp';
const ANDROID_USER_AGENT = 'android';

export const isInChinaOtterApp = (): boolean =>
  window.navigator.userAgent.indexOf(CN_OTTER_APP_USER_AGENT) >= 0;

export const isInChinaOtterAppAndroid = (): boolean =>
  isInChinaOtterApp() &&
  window.navigator.userAgent.toLowerCase().includes(ANDROID_USER_AGENT);

export const getAppVersion = (): string => {
  console.warn('agent', window.navigator.userAgent);
  const RE_APP_VERSION = /OtterApp\/.*?\/(.*)$/;
  return (
    window.navigator.userAgent
      .match(RE_APP_VERSION)?.[1]
      ?.replace(/\(.+\)/, '') ?? ''
  );
};

// unreliable way to check if in a mobile browser
export const isInMobileBrowserNotApp = (): boolean =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  ) && !isInChinaOtterApp();
