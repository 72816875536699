import { useQuery, UseQueryResult } from "react-query";
import { fetchClient } from "../../../common/util/fetchClient";
import { useCurrentStore } from "../../../hooks/useCurrentStore";
import { PRODUCT_NAME as OREDER_BOOSTER_PRODUCT_NAME } from "../../cashback/constants";

type SubscriptionAccess = Array<{
  feature: string;
  product: string;
}>;

type ProductSubscriptionsResponse = {
  data: {
    lutrahubProductAccess: {
      productAccess: SubscriptionAccess;
    };
  };
  errors: { message: string }[];
};

const useProductSubscriptionsQuery = (): UseQueryResult<SubscriptionAccess> => {
  const storeId = useCurrentStore((state) => state.storeId);
  return useQuery<ProductSubscriptionsResponse, unknown, SubscriptionAccess, string[]>(
    ["product-subscriptions-data"],
    async function () {
      return await fetchClient
        .get<ProductSubscriptionsResponse>({
          path: `/graphql`,
          method: "POST",
          requestParams: {
            data: {
              query: ProductSubscriptions,
              variables: {
                input: {
                  storeId,
                  status: "IN_USE",
                  pagination: { first: 1000 },
                },
              },
            },
          },
        })
        .then((res) => {
          if (res.errors?.length > 0) {
            console.error(res.errors);
          }
          return res;
        });
    },
    {
      enabled: !!storeId,
      staleTime: 0,
      select: (res) => res?.data?.lutrahubProductAccess.productAccess,
    },
  );
};

const useIsGoXcd = () => {
  const { data, isLoading } = useProductSubscriptionsQuery();

  const isXcdInUse = data?.find((o) => o.product === "xcd");
  const isOrderBoosterInUse = data?.find((o) => o.product === OREDER_BOOSTER_PRODUCT_NAME);

  if (isXcdInUse && isOrderBoosterInUse) {
    throw new Error("xcd and orderbooster cannot be used at the same time");
  }

  return {
    isLoading,
    isGoXcd: isXcdInUse ? true : isOrderBoosterInUse ? false : data ? true : undefined,
  };
};
export default useIsGoXcd;

const ProductSubscriptions = `query subscriptions($input: QueryLutrahubProductAccessInput) {
    lutrahubProductAccess(input: $input) {
      productAccess {
        product
        feature
    }
  }
}`;
