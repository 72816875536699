import { isProductionEnvironment } from "../config/config";

export const routingPaths = {
  // home: "/",
  // login: "/login",
  // authError: "/autherror",
  // report: "/report",
  // activate: "/activate",
  // selectStore: "/selectstore",
  orderList: "/orderlist",
  // startTrial: "/trial",
  // marketing: "/marketing",
  // landing: "/landing",
  // payment: "/payment",
  // couponSetting: "/coupon-setting",
  // iap: "/iap",
  xcd: "/xcd/*",
  cashback: "/cashback/*",
  transition: "/transition",
};

export const staticPaths = {
  marketing: "/static/marketing/index.html",
};

export const apiErrorCodes = {
  toBeActivate: "100400001",
};

export const noop = (): void => {};

export const BAAS_HOSTNAME = isProductionEnvironment
  ? "https://baas.csschina.com"
  : "https://baas-staging.csschina.com";
