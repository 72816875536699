import { createConfigWithDerivedApiUrl, getEnvFromHostname } from '@css/op-url-config';
import prodConfig from './config-production.json';
import devConfig from './config-dev.json';
import stagConfig from './config-staging.json';
import integrationConfig from './config-integration.json';

interface AppConfig {
  env?: string;
  SENTRY_DSN?: string;
  APP_NAME: string;
  API_URL: string;
  CSS_CHINA_API_URL: string;
  ROSETTA_PROJECT_NAME: string;
  // There are currently no translations available in staging, so we point to prod.
  // This flag has no effect when passed to the Rosetta Client with a BASE_URI_OVERRIDE
  ROSETTA_USE_STAGING?: boolean;
  // Use this flag only on local (config.json), if you want to point to a deployed Rosetta
  // https://rosetta-staging.tryotter.com/ for staging
  // https://rosetta.tryotter.com/ for prod
  ROSETTA_BASE_URI_OVERRIDE?: string;
  CUSTOMER_SUPPORT_NUMBER: string;
  FLAGSHIP_NAMESPACE: string;
  DEFAULT_CONTACT_US_PHONE_NUMBER: string;
  DEFAULT_FREE_TRIAL_COUPON_THRESHOLD: number;
  DEFAULT_FREE_TRIAL_COUPON_VALUE: number;
  MIXPANEL_PROJECT_TOKEN: string;
  MIXPANEL_DEBUG_PROJECT_TOKEN: string;
}

export const isStagingFromEV = import.meta.env.VITE_API === 'staging';
export const envFromHostname = getEnvFromHostname();
export const isProductionEnvironment = envFromHostname === 'production';

// let envConfig = {};
// // let env = 'dev';
// try {
//   switch (envFromHostname) {
//     case 'production':
//       envConfig = require('./config-production.json');
//       break;
//     case 'staging':
//       envConfig = require('./config-staging.json');
//       break;
//     default:
//       envConfig = require('./config-dev.json');
//       break;
//   }
// } catch (err) {
//   console.error(err);
// }

const envConfig =
  envFromHostname === 'production'
    ? prodConfig
    : envFromHostname === 'integration'
    ? integrationConfig
    : envFromHostname === 'staging' || isStagingFromEV
    ? stagConfig
    : envFromHostname === 'local'
    ? devConfig
    : {};

export const getEnvName = (): string => envFromHostname;

const defaultConfig: AppConfig = {
  // Add any dev or default configs here, but note that they will be overridden
  // by values in `src/config.json` if present.
  APP_NAME: 'ecd-web-view',
  CSS_CHINA_API_URL: 'https://fc-api.csschina.com',
  API_URL: 'https://api-staging.cloudkitchens.com',
  ROSETTA_PROJECT_NAME: 'rosetta-ecd-web-view',
  ROSETTA_USE_STAGING: false,
  ROSETTA_BASE_URI_OVERRIDE: 'https://rosetta.cloudkitchens.com',
  CUSTOMER_SUPPORT_NUMBER: '',
  FLAGSHIP_NAMESPACE: 'ecd-web',
  DEFAULT_CONTACT_US_PHONE_NUMBER: '18918275310',
  DEFAULT_FREE_TRIAL_COUPON_THRESHOLD: 9,
  DEFAULT_FREE_TRIAL_COUPON_VALUE: 6,
  MIXPANEL_PROJECT_TOKEN: '5b524cb0cbb07ff07a285b264d142665',
  MIXPANEL_DEBUG_PROJECT_TOKEN: 'be183b023289d734262f0e2d67f193a7',
};

const getApiUrlByHostname = (env: typeof envFromHostname): { API_URL?: string; ROSETTA_BASE_URI_OVERRIDE?: string } => {
  if (env === 'local') {
    //use envConfig
    return {};
  }
  const apiUrlConfig = {
    API_URL: { ...defaultConfig, ...envConfig }.API_URL,
    // in production env, if domain name is bitime.cn use api-v2.shuitazhanggui.com, use api.xxx.com for others domain name
    API_SUBDOMAIN:
      env === "production"
        ? window.location.hostname.endsWith(".shuitazhanggui.com")
          ? "api-v2"
          : "api"
        : env === "integration"
        ? "api-integration"
        : "api-staging",
  };
  const { API_URL } = createConfigWithDerivedApiUrl(apiUrlConfig);
  return {
    API_URL,
    ROSETTA_BASE_URI_OVERRIDE: createConfigWithDerivedApiUrl({ API_SUBDOMAIN: 'rosetta' }).API_URL,
  };
};

const apiUrlByHostname = getApiUrlByHostname(envFromHostname);

const appConfig = { ...defaultConfig, ...envConfig, ...apiUrlByHostname, env: envFromHostname };

export default appConfig;
