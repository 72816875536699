import { useMemo } from 'react';
import { useLocation } from 'react-router';

const useSearchParams = (): { [k: string]: string } => {
  const { search } = useLocation();
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return Object.fromEntries(searchParams);
  }, [search]);

  return params;
};

export default useSearchParams;

export const encodeSearchParams = (params: { [k: string]: any }): string =>
  `?${new URLSearchParams(params).toString()}`;
