import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import appConfig from "../../config/config";

Sentry.init({
  dsn: appConfig.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  maxBreadcrumbs: 50,
  denyUrls: [
    /https?:\/\/192\.168\.99\.100:3000(\/*)?/, // local host
    /https?:\/\/106\.14\.197\.201(\/*)?/, // aliyun ip
  ],
  tracesSampleRate: 0.5,
  normalizeDepth: 11,
  attachStacktrace: true,
});
