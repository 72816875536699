import { AppConfig, createDefaultFetchClient } from "@css/web-core";

import CONFIG from "../../config/config";
import { initFetchTracker } from "../lytics";

const getUserAgent = (): AppConfig => {
  return {
    name: "@mission-control/app-ecd-dashboard",
    version: String(import.meta.env.REACT_APP_VERSION),
  };
};

initFetchTracker();

export const legacyFetchClient = createDefaultFetchClient({
  appConfig: getUserAgent(),
  baseUrl: CONFIG.CSS_CHINA_API_URL,
  retryOpts: { retries: 2 },
});

export const fetchClient = createDefaultFetchClient({
  appConfig: getUserAgent(),
  baseUrl: CONFIG.API_URL,
  retryOpts: { retries: 2 },
});

export const fetchClientWithoutRetry = createDefaultFetchClient({
  appConfig: getUserAgent(),
  baseUrl: CONFIG.API_URL,
});
